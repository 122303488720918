<template>
  <sm-categories-lists
    :breadcrumbs="breadcrumbs"
    :categories-group-name="categoriesGroupName"
    :list-name="categoriesGroupName"
  />
</template>

<script>
// components
import SmCategoriesLists from '@/components/views/SmCategoriesLists.vue';

export default {
  name: 'TelemarketingMain',

  components: {
    SmCategoriesLists,
  },

  data() {
    return {
      breadcrumbs: [{ text: 'Телемаркетинг' }],

      categoriesGroupName: 'telemarketing',
      categoriesGroup: [
        {
          list: [
            {
              name: 'Задания по телемаркетингу',
              route: { name: 'TelemarketingTasks' },
              controllerName: 'TelemarketingTasks',
            },
            {
              name: 'Задания по телемаркетингу для текущего пользователя',
              route: { name: 'TelemarketingTasksForCurrentUser' },
              controllerName: 'TelemarketingTasksForCurrentUser',
            },
            {
              name: 'Множественное создание задания по телемаркетингу',
              route: { name: 'TelemarketingTasksCreateMany' },
              controllerName: 'TelemarketingTasks',
            },
            {
              name: 'Организации',
              route: { name: 'Organizations' },
              controllerName: 'Organizations',
            },
            {
              name: 'Причины отказа для телемаркетинга',
              route: { name: 'TelemarketingTasksRejectionReasons' },
              controllerName: 'TelemarketingTasksRejectionReasons',
            },
            {
              name: 'Звонки по телемаркетингу',
              route: { name: 'SipCalls' },
              controllerName: 'SipCalls',
            },
            {
              name: 'Отчет по телемаркетингу',
              route: { name: 'TelemarketingReport' },
              controllerName: 'TelemarketingReport',
            },
            {
              name: 'Логи отправки писем',
              route: { name: 'SendMailHistory' },
              controllerName: 'SendMailHistory',
            },
          ],
        },
        {
          title: 'Задачи',
          list: [
            {
              name: 'Продукты телемаркетинга',
              route: { name: 'TelemarketingProducts' },
              controllerName: 'TelemarketingProducts',
            },
            {
              name: 'Скрипты телемаркетинга',
              route: { name: 'TelemarketingScripts' },
              controllerName: 'TelemarketingScripts',
            },
          ],
        },
      ],
    };
  },
};
</script>
